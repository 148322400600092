<template>
  <el-main>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="合伙人姓名：">
        <el-input size="small" v-model="searchForm.name" placeholder="请输入合伙人姓名"></el-input>
      </el-form-item>
      <el-form-item label="合伙人电话：">
        <el-input size="small" v-model="searchForm.mobile" placeholder="请输入合伙人电话"></el-input>
      </el-form-item>
      <el-form-item label="代理商姓名：">
        <el-input size="small" v-model="searchForm.fname" placeholder="请输入代理商姓名"></el-input>
      </el-form-item>
      <el-form-item label="代理商电话：">
        <el-input size="small" v-model="searchForm.fmobile" placeholder="请输入代理商电话"></el-input>
      </el-form-item>
      <el-form-item label="状态：">
        <el-select v-model="searchForm.status" size="small" placeholder="请选择">
          <el-option label="全部" value=""></el-option>
          <el-option label="正常" :value="1"></el-option>
          <el-option label="审核中" :value="0"></el-option>
          <el-option label="清退" :value="-1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="激活时间：">
        <el-date-picker size="small" v-model="searchForm.start_time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
      </el-form-item>
      <el-form-item label="是否统计：">
        <el-select v-model="searchForm.is_statistics" size="small" placeholder="请选择">
          <el-option label="全部" value=""></el-option>
          <el-option label="统计" :value="1"></el-option>
          <el-option label="不统计" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" " label-width="15px">
        <el-button type="primary" size="small" @click="search()">搜索</el-button>
        <el-button size="small" @click="exportList">导出</el-button>
        <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <div class="total-num">
      <div>筛选后总收益：{{ total_amount }}</div>
      <div>筛选后总余额：{{ amount }}</div>
      <div>筛选后总提现金额：{{ withdrawal_amount }}</div>
    </div>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="name" label="合伙人姓名" align="center"></el-table-column>
      <el-table-column prop="mobile" label="合伙人电话" align="center"></el-table-column>
      <el-table-column prop="f_name" label="代理商姓名" align="center">
        <template v-slot="{ row }">
          {{ row.facilitator_id ? row.f_name : '总部' }}
        </template>
      </el-table-column>
      <el-table-column prop="f_mobile" label="代理商电话" align="center">
        <template v-slot="{ row }">
          {{ row.facilitator_id ? row.f_mobile : '总部' }}
        </template>
      </el-table-column>
      <el-table-column prop="total_qrcode" label="总计二维码数" align="center"></el-table-column>
      <el-table-column prop="commission_ratio" label="收益比例" align="center">
        <template v-slot="{ row }">{{ row.commission_ratio }}%</template>
      </el-table-column>
      <el-table-column prop="total_amount" align="center">
        <template #header>
          <div @click="sortList('total_amount')" class="sortList">
            <div>总收益</div>
            <i :style="{ color: orderBy == 'total_amount' ? '#409EFF' : '' }" class="el-icon-caret-bottom"></i>
          </div>
        </template>
        <template v-slot="{ row }">￥{{ row.total_amount }}</template>
      </el-table-column>
      <el-table-column prop="amount" align="center">
        <template #header>
          <div @click="sortList('amount')" class="sortList">
            <div>当前余额</div>
            <i :style="{ color: orderBy == 'amount' ? '#409EFF' : '' }" class="el-icon-caret-bottom"></i>
          </div>
        </template>
        <template v-slot="{ row }">￥{{ row.amount }}</template>
      </el-table-column>
      <el-table-column prop="withdrawal_amount" align="center" width="120px">
        <template #header>
          <div @click="sortList('withdrawal_amount')" class="sortList">
            <div>累计提现金额</div>
            <i :style="{ color: orderBy == 'withdrawal_amount' ? '#409EFF' : '' }" class="el-icon-caret-bottom"></i>
          </div>
        </template>
        <template v-slot="{ row }">￥{{ row.withdrawal_amount }}</template>
      </el-table-column>
      <el-table-column prop="act_num" label="已激活二维码数" align="center"></el-table-column>
      <el-table-column prop="status" label="状态" align="center">
        <template v-slot="{ row }">
          {{ row.status == 1 ? '正常' : row.status == 0 ? '审核中' : '清退' }}
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="注册时间" align="center">
        <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
      </el-table-column>
      <el-table-column label="操作" align="center" fixed="right">
        <template v-slot="{ row }">
          <el-button @click="operation(row, 4)" type="text" size="small" v-if="row.status == 0">审核</el-button>
          <el-button @click="operation(row, 2)" type="text" size="small" v-if="row.status == 1">清退</el-button>
          <el-button @click="operation(row, 5)" type="text" size="small">{{ row.is_black ? '恢复' : '拉黑' }}</el-button>
          <el-button @click="operation(row, 3)" type="text" size="small">查看明细</el-button>
          <!-- <el-button @click="operation(row, 4)" type="text" size="small" style="color: #f56c6c">{{ row.is_statistics ? '统计' : '不统计' }}</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
    <el-dialog title="查看明细" :visible.sync="showDetailed" width="780px">
      <el-table :data="balanceList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
        <template slot="empty">
          <No />
        </template>
        <el-table-column prop="create_time" label="生成时间" align="center">
          <template v-slot="{ row }">
            {{ getDateformat(row.create_time) }}
          </template>
        </el-table-column>
        <el-table-column prop="type" label="收益支出" align="center">
          <template v-slot="{ row }">
            {{ dealStatus(Number(row.type)) }}
          </template>
        </el-table-column>
        <el-table-column prop="amount" label="预估收益" align="center">
          <template v-slot="{ row }">{{ row.income_type == 1 ? '+' : '-' }}{{ row.amount }}</template>
        </el-table-column>
      </el-table>
      <Paging :total="balance_total" :page="balance_page" :pageNum="balance_rows" @updatePageNum="balanceUpdateData"></Paging>
    </el-dialog>
  </el-main>
</template>

<script>
import _ from 'lodash';
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      searchForm: {
        page: 1,
        rows: 10,
        name: '',
        mobile: '',
        fname: '',
        fmobile: '',
        start_time: '',
        end_time: '',
        status: '',
        is_statistics: '',
      },
      id: '',
      list: [],
      balanceList: [],
      total: 0,
      showDetailed: !1,
      balance_page: 1,
      balance_rows: 10,
      balance_total: 0,
      // 排序方式
      orderBy: '',
      total_amount: '',
      amount: '',
      withdrawal_amount: '',
    };
  },
  created() {
    this.getList();
  },
  methods: {
    dealStatus(type) {
      switch (type) {
        case 1:
          return 'wifi';
          break;
        case 2:
          return '第三方活动';
          break;
        case 3:
          return '扫码点餐';
          break;
        case 4:
          return '提现';
          break;
        case 5:
          return '全真支付';
          break;
        case 6:
          return '连接激励奖励';
          break;
        case 7:
          return '消费激励奖励';
          break;
        case 8:
          return 'CPS商品分润';
          break;
        case 10:
          return '话费';
          break;
        case 20:
          return '直充';
          break;
        case 30:
          return '吃喝卡券';
          break;
        case 40:
          return '电费';
          break;
        case 50:
          return '广告金';
          break;
        case 60:
          return '小程序上线奖励';
          break;
        case 70:
          return '点餐流水奖励';
          break;
        case 80:
          return '配送抽佣奖励';
          break;
        case 90:
          return '贴码视频奖励';
          break;
        default:
          return '未知状态';
          break;
      }
    },
    getDateformat(val) {
      return getDateformat(val);
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
      } else {
        this.searchForm.page = val;
      }
      this.getList();
    },
    search(is_export) {
      this.searchForm.page = 1;
      if (is_export) {
        console.log(is_export);
        //this.getList(is_export);
        return;
      }
      this.getList();
    },
    clearSearch() {
      this.searchForm = {
        page: 1,
        rows: 10,
        name: '',
        mobile: '',
        fname: '',
        fmobile: '',
        start_time: '',
        end_time: '',
        status: '',
        is_statistics: '',
      };
      this.orderBy = '';
      this.getList();
    },
    balanceUpdateData(val, status) {
      if (status == 0) {
        this.balance_rows = val;
      } else {
        this.balance_page = val;
      }
      this.getBalanceList();
    },
    exportList() {
      let searchForm = _.cloneDeep(this.searchForm);
      delete searchForm.rows;
      delete searchForm.page;
      this.$axios.post(this.$api.serviceProvider.regimenList, { ...searchForm, is_export: 1 }).then(res => {
        if (res.code == 0) {
          let a = document.createElement('a');
          a.href = res.result;
          a.id = 'download';
          document.body.appendChild(a);
          a.click();
          let aDom = document.getElementById('download');
          document.body.removeChild(aDom);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    sortList(key) {
      this.orderBy = this.orderBy == key ? '' : key;
      this.searchForm.page = 1;
      this.getList();
    },
    getList(is_export) {
      let searchForm = _.cloneDeep(this.searchForm);
      if (searchForm.start_time) {
        searchForm.end_time = searchForm.start_time[1] / 1000;
        searchForm.start_time = searchForm.start_time[0] / 1000;
      }
      if (this.orderBy) {
        searchForm.order = this.orderBy;
      } else {
        delete searchForm.order;
      }
      if (is_export) searchForm.is_export = 1;
      this.$axios.post(this.$api.serviceProvider.regimenList, searchForm).then(res => {
        if (res.code == 0) {
          if (is_export) {
            let a = document.createElement('a');
            a.href = res.result;
            a.id = 'download';
            document.body.appendChild(a);
            a.click();
            let aDom = document.getElementById('download');
            document.body.removeChild(aDom);
          } else {
            this.list = res.result.list;
            this.total = res.result.total;
            this.total_amount = res.result.total_amount || 0.0;
            this.amount = res.result.amount || 0.0;
            this.withdrawal_amount = res.result.withdrawal_amount || 0.0;
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getBalanceList() {
      this.$axios
        .post(this.$api.serviceProvider.BalanceLog, {
          page: this.balance_page,
          rows: this.balance_rows,
          identity_type: 3,
          regimental_id: this.id,
        })
        .then(res => {
          if (res.code == 0) {
            this.showDetailed = !0;
            this.balanceList = res.result.list;
            this.balance_total = res.result.total_number;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    operation(row, type) {
      if (type == 2) {
        this.$confirm('请确认是否清退该合伙人？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.$axios
            .post(this.$api.serviceProvider.regimenCheck, {
              id: row.id,
              status: -1,
            })
            .then(res => {
              if (res.code == 0) {
                this.$message.success('清退成功');
                row.status = -1;
              } else {
                this.$message.error(res.msg);
              }
            });
        });
      }
      if (type == 3) {
        this.id = row.id;
        this.balance_page = 1;
        this.getBalanceList();
      }
      if (type == 4) {
        // this.$axios
        //   .post(this.$api.serviceProvider.isStatisticsRegimental, {
        //     id: row.id,
        //     is_statistics: row.is_statistics ? 0 : 1,
        //   })
        //   .then(res => {
        //     if (res.code == 0) {
        //       row.is_statistics = row.is_statistics ? 0 : 1;
        //     } else {
        //       this.$message.error(res.msg);
        //     }
        //   });
        this.$confirm('确定是否审核合伙人?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            return this.$axios.post(this.$api.serviceProvider.shopRegimentalAudit, {
              id: row.id,
              status: 1,
            });
          })
          .then(res => {
            if (res.code == 0) {
              row.status = 1;
              this.$message.success('审核成功');
            } else {
              this.$message.error(res.msg);
            }
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            });
          });
      }
      if (type == 5) {
        if (row.is_black) {
          this.$axios
            .post(this.$api.serviceProvider.shopRegimentalBlack, {
              id: row.id,
              is_black: 0,
            })
            .then(res => {
              if (res.code == 0) {
                row.is_black = 0;
                this.$message.success('已恢复');
              } else {
                this.$message.error(res.msg);
              }
            });
        } else {
          this.$confirm('请确认是否拉黑该合伙人？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(() => {
            this.$axios
              .post(this.$api.serviceProvider.shopRegimentalBlack, {
                id: row.id,
                is_black: 1,
              })
              .then(res => {
                if (res.code == 0) {
                  row.is_black = 1;
                  this.$message.success('已拉黑');
                } else {
                  this.$message.error(res.msg);
                }
              });
          });
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;

  .total-num {
    display: flex;
    font-weight: bold;
    margin: 20px 0;

    div {
      margin-right: 40px;
    }
  }

  .sortList {
    white-space: nowrap;
    display: flex;
    align-items: center;
  }
}
</style>
